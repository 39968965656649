import './../App.css';
import {useController, useForm} from "react-hook-form";
import {useState} from "react";
import {useNavigate} from "react-router-dom";

const TELEGRAM_BOT_TOKEN = '7705924266:AAFQYsn8cstzWq_xabaSAzMWYbCQKkl7cgQ';
const CHAT_ID = '8002226665';

export default function Root() {
    const navigate = useNavigate();

    const [isSubmitted, setIsSubmitted] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);

    const {handleSubmit, control} = useForm({
        initialValues: {
            surname: '', name: '', phone: '', city: '', years: '', experience: '',
        }, mode: 'onChange', reValidateMode: "onChange",
    });

    const {field: fieldSurname} = useController({control, name: 'surname'});
    const {field: fieldName} = useController({control, name: 'name'});
    const {field: fieldPhone} = useController({control, name: 'phone'});
    const {field: fieldCity} = useController({control, name: 'city'});
    const {field: fieldYears} = useController({control, name: 'years'});
    const {field: fieldExperience} = useController({control, name: 'experience'});

    const onSubmit = (data) => {
        const message = `Прізвище: ${data.surname}\nІмʼя: ${data.name}\nНомер телефону: ${data.phone}\nМісто: ${data.city}\nВік: ${data.years}\nДосвід: ${data.experience}`;

        setIsLoading(true);
        fetch(`https://api.telegram.org/bot${TELEGRAM_BOT_TOKEN}/sendMessage`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                chat_id: CHAT_ID,
                text: message,
                parse_mode: 'HTML' // Use 'Markdown' or 'HTML' if you want to format text
            })
        })
            .then(response => {
                if (!response.ok) {
                    setIsSuccess(false);
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(() => {
                setIsSuccess(true);

            })
            .catch(() => {
                setIsSuccess(false);
            })
            .finally(() => {
                setIsLoading(false);
                setIsSubmitted(true);
            });
    };


    const renderResult = () => {
        if(isSuccess){
            navigate('/thanks');
            return null;
        }

        return (
            <div className="popup-backdrop">
                <div className="popup">
                    <span className="title">Помилка.</span>
                    <span className="content">Презавантажте сторінку та спробуйте ще раз.</span>
                </div>
            </div>
        );
    }

    return (<div className="app">
        {isSubmitted && renderResult()}
        <div className="blurred">
            <form className="container" onSubmit={handleSubmit(onSubmit)}>
                <div className="header">
                    <span className="text">Заповни анкету</span>
                </div>
                <div className="main-form">
                    <label>
                        <span>Прізвище</span>
                        <input {...fieldSurname} type="text" maxLength={100} required/>
                    </label>
                    <label>
                        <span>Ім'я</span>
                        <input {...fieldName} type="text" maxLength={100} required/>
                    </label>
                    <label>
                        <span>Номер телефону</span>
                        <input {...fieldPhone} type="text" maxLength={20} required/>
                    </label>
                    <label>
                        <span>Місто</span>
                        <input {...fieldCity} type="text" maxLength={100} required/>
                    </label>
                    <label>
                        <span>Скільки років?</span>
                        <input {...fieldYears} type="text" maxLength={30} required/>
                    </label>
                    <label>
                        <span>Чи є досвід роботи?</span>
                        <input {...fieldExperience} type="text" maxLength={200} required/>
                    </label>
                </div>
                <button onSubmit={handleSubmit(onSubmit)} className="submit-button" disabled={isLoading}>
                    Відправити анкету
                </button>
            </form>
        </div>
    </div>);
}